/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import BreakageService from '../../../service/BreakageService';
import DropdownClients from '../../../components/DropdownClients';
import classes from './SalesmanMonthlyCommissionReport.module.scss';

const BreakageReport = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [clientIds, setClientIds] = useState([]);
    const [fromDate, setFromDate] = useState(moment().startOf('month').format());
    const [toDate, setToDate] = useState(moment().endOf('month').format());
    const toast = useRef<any>(null);
    const [rewardBreakageList, setRewardBreakageList] = useState<any[]>([]);
    let clientsList: any[] = [];
    let ordersList: any[] = [];
    let rewardsList: any[] = [];

    const getRewardBreakageList = async (query?: any, params?: any) => {
        try {
            await BreakageService.getAll(query, params).then((data) => {
                // result grouped for clientName and countruName
                const results = _.groupBy(data, (item) => {
                    return [item.clientName, item.countryName];
                });

                // clear list
                clientsList = [];
                ordersList = [];
                rewardsList = [];

                // Prepare a list of reward breakage list
                Object.values(results as any).map((itens: any) => {
                    if (itens) {
                        for (let index = 0; index < itens.length; index++) {
                            const element = itens[index];
                            let totalOrder = 0;
                            let totalPending = 0;
                            let totalConfirmed = 0;
                            let totalDeclined = 0;

                            if (element.total) {
                                totalOrder += element.total;
                            }

                            switch (element.status) {
                                case 'THIRD_PARTY_PENDING':
                                    totalPending = element.total;
                                    break;
                                case 'PAID':
                                    totalConfirmed = element.total;
                                    break;
                                case 'CANCELLED':
                                    totalDeclined = element.total;
                                    break;
                                default:
                            }

                            if (!clientsList || !clientsList.find((f) => f.name === `${element.clientName}-${element.countryName}`)) {
                                clientsList.push({
                                    name: `${element.clientName}-${element.countryName}`,
                                    clientId: element.clientId
                                });
                            }

                            const existOrderMonth = ordersList.find((f) => f.month === element.month && f.clientName === `${element.clientName}-${element.countryName}`);
                            if (existOrderMonth) {
                                existOrderMonth.totalOrders += totalOrder;
                                existOrderMonth.totalPending += totalPending;
                                existOrderMonth.totalConfirmed += totalConfirmed;
                                existOrderMonth.totalDeclined += totalDeclined;
                            } else {
                                ordersList.push({
                                    month: element.month,
                                    totalOrders: totalOrder,
                                    clientId: element.clientId,
                                    clientName: `${element.clientName}-${element.countryName}`,
                                    totalPending,
                                    totalConfirmed,
                                    totalDeclined
                                });
                            }

                            const existRewardMonth = rewardsList.find((f) => f.month === element.month && f.clientName === `${element.clientName}-${element.countryName}`);
                            if (existRewardMonth) {
                                existRewardMonth.giftCardEmailSent += element.GiftCardEmailSent;
                                existRewardMonth.giftCardPending += element.GiftCardPending;
                                existRewardMonth.giftCardReedemed += element.GiftCardReedemed;
                                existRewardMonth.giftCardExpired += element.GiftCardExpired;
                            } else {
                                rewardsList.push({
                                    month: element.month,
                                    name: element.rewardName,
                                    clientId: element.clientId,
                                    clientName: `${element.clientName}-${element.countryName}`,
                                    giftCardEmailSent: element.GiftCardEmailSent,
                                    giftCardPending: element.GiftCardPending,
                                    giftCardReedemed: element.GiftCardReedemed,
                                    giftCardExpired: element.GiftCardExpired
                                });
                            }
                        }
                    }
                });

                const clientsOrdersRewards: any[] = [];
                clientsList.forEach((client: any) => {
                    clientsOrdersRewards.push({
                        client: {
                            name: client.name,
                            orders: ordersList.filter((f) => f.clientName === client.name),
                            rewards: rewardsList.filter((f) => f.clientName === client.name)
                        }
                    });
                });

                setRewardBreakageList(clientsOrdersRewards);
            });
        } catch (error: any) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error?.friendlyMessage,
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const getQuery = () => {
        let query: any = {
            $offset: 0,
            $limit: 1000
        };

        const gteDate = fromDate ? { $date_$gte: moment(fromDate).startOf('month').format('YYYY-MM') } : {};
        const lteDate = toDate ? { $date_$lte: moment(toDate).endOf('month').format('YYYY-MM') } : {};
        query = {
            ...query,
            ...gteDate,
            ...lteDate
        };

        if (clientIds && clientIds.length > 0) {
            query.clientIds = clientIds.map((item: any) => {
                return item._id;
            });
        }

        return query;
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setIsVisible(true);
        getRewardBreakageList('', getQuery());
    };

    const templateMonthValue = (item: string, row: any) => {
        if (item === 'giftCardUsage') {
            const soma = ((row.giftCardReedemed / row.giftCardEmailSent) * 100).toFixed(2);
            return `${!Number.isNaN(Number(soma)) ? soma : 0} %`;
        }

        if (item === 'giftBreakage') {
            const result = (row.giftCardExpired / row.giftCardEmailSent).toFixed(2);

            return !Number.isNaN(Number(result)) ? result : 0;
        }

        return row[item];
    };

    return (
        <div>
            <div className="card p-fluid">
                <Toast ref={toast} />
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="orderPostRewadClient">Client</label>
                        <DropdownClients id="clientId" reward value={clientIds} onChange={(e: any) => setClientIds(e.value)} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className={`field col-6 ${classes.centeContent}`}>
                        <label htmlFor="publishedDate">Start Date</label>
                        <Calendar dateFormat="mm/yy" id="fromDate" value={fromDate && new Date(fromDate)} onChange={(e: any) => setFromDate(e.value)} showOnFocus={false} view="month" showIcon />
                    </div>
                    <div className={`field col-6 ${classes.centeContent}`}>
                        <label htmlFor="publishedDate">End Date</label>
                        <Calendar dateFormat="mm/yy" id="toDate" value={toDate && new Date(toDate)} onChange={(e: any) => setToDate(e.value)} showOnFocus={false} view="month" showIcon />
                    </div>
                </div>
                <div className="field col-2">
                    <Button label="Search" type="submit" onClick={onSubmit} />
                </div>
            </div>
            <div>
                <DataTable loading={loading && rewardBreakageList.length <= 0} value={[1]} />
                {rewardBreakageList.length > 0 &&
                    rewardBreakageList.map((item) => {
                        return (
                            <div hidden={!isVisible} className="card p-d-flex p-flex-column justify-content-center">
                                <h2>{item.client.name}</h2>
                                <Panel header="">
                                    <DataTable
                                        value={[
                                            { name: 'Orders', prop: 'totalOrders' },
                                            { name: 'Pending', prop: 'totalPending' },
                                            { name: 'Declined', prop: 'totalDeclined' },
                                            { name: 'Confirmed', prop: 'totalConfirmed' }
                                        ]}
                                        loading={loading}
                                    >
                                        <Column header="" body={(e) => e.name} />

                                        {item.client.orders.map((li: any) => (
                                            <Column header={li.month} body={(e) => templateMonthValue(e.prop, li)} />
                                        ))}
                                    </DataTable>
                                </Panel>
                                <br />
                                <h6>Reward: {item.client.rewards.find((f: any) => f.clientName === item.client.name).name}</h6>
                                <Panel header="">
                                    <DataTable
                                        value={[
                                            { name: 'Gift Card Emails Sent', prop: 'giftCardEmailSent' },
                                            { name: 'Gift Card Pending', prop: 'giftCardPending' },
                                            { name: 'Gift Card Redeemed', prop: 'giftCardReedemed' },
                                            { name: 'Gift Card Expired', prop: 'giftCardExpired' },
                                            { name: 'Gift Card Usage', prop: 'giftCardUsage' },
                                            { name: 'Gift Breakage', prop: 'giftBreakage' }
                                        ]}
                                        loading={loading}
                                    >
                                        <Column header="" body={(e) => e.name} />

                                        {item.client.rewards.map((li: any) => (
                                            <Column header={li.month} body={(e) => templateMonthValue(e.prop, li)} />
                                        ))}
                                    </DataTable>
                                </Panel>
                            </div>
                        );
                    })}
                {rewardBreakageList.length <= 0 && isVisible && !loading && <DataTable value={[]} />}
            </div>
        </div>
    );
};

export default BreakageReport;
